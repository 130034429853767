<template>
  <div
    class="col-sm-3 px-1 mb-2"
    @click="goToForm(form, id)"
  >
    <div class="row mx-0">
      <div class="col-sm-12 bg-gray border-radius cursor-pointer">
        <div class="row">
          <div class="col-sm-12 py-2">
            <span class="h5 text-success font-weight-bold">N° O.T.: 01/24</span>
          </div>
        </div>
        <div class="row text-right">
          <div class="col-sm-12">
            <b class="text-dark">Paciente: Jose Ledezma</b>
          </div>
          <div class="col-sm-12 line-height-1">
            <b class="text-gray">Dermatitis</b>
          </div>
          <div class="col-sm-12 line-height-1">
            <small>28/02/2024 - Hora: 10:33</small>
          </div>
          <div class="col-sm-12 line-height-1">
            <small>N° VS:22/24</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-11 offset-sm-1">
            <b class="text-orange">Dr. Carmen Torrez (Horario)</b>
          </div>
          <div class="col-sm-11 offset-sm-1">
            <small>F/Solicitud: 01/01/2024</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrdenItem',
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    form: {
      type: [Number, String],
      default: 1,
      validator(value) {
        // Form 1, Form 2, Form 3
        return [1, 2, 3].includes(parseInt(value, 10));
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    goToForm(form, id) {
      const tipoForm = parseInt(form, 10);
      switch (tipoForm) {
        case 1:
          this.$router.push({ name: 'orden-trabajo-form1', params: { id: this.encrypt(id) } });
          break;
        case 2:
          this.$router.push({ name: 'orden-trabajo-form2', params: { id: this.encrypt(id) } });
          break;
        default:
          break;
      }
    },
    encrypt(id) {
      return btoa(id);
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: rgba(232, 232, 232, 0.971);
}
.text-gray {
  color:#858189;
}
.border-radius {
  border-radius: 10px;
}
.line-height-1 {
  line-height: 1;
}
</style>
