<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-4">
                <div class="breadcrumb mb-2 custom-top-padding">
                  <h1 class="mb-0"><strong>Orden de Trabajo</strong></h1>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-5">
                  <div class="text-right">
                    <label
                      class="switch switch-success mr-3"
                    >
                      <input
                        id="filter"
                        type="checkbox"
                        :checked="estado"
                        v-model="estado"
                        @change="getData"
                      >
                      <span class="slider"></span>
                      <span>{{ labelActivos }}</span>
                    </label>
                  </div>
                </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mx-0">
              <OrdenItem form="1" :id="1" />
              <OrdenItem form="2" :id="2" />
            </div>
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="paginaActual"
                  :total-rows="totalFilas"
                  :per-page="porPagina"
                  class="my-0 float-right"
                  @input="getData"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrdenItem from '../components/OrdenItem.vue';

export default {
  name: 'index',
  components: {
    OrdenItem,
  },
  data() {
    return {
      estado: true,
      paginaActual: 1,
      totalFilas: 0,
      porPagina: 100,
    };
  },
  methods: {
    getData() {},
    goToForm1(id) {
      this.$router.push({ name: 'orden-trabajo-medico', params: { id: this.encrypt(id) } });
    },
    encrypt(id) {
      return btoa(id);
    },
  },
  computed: {
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
  },
};
</script>

<style scoped>
.custom-top-padding {
  padding-top: 5.53px;
}
</style>
